import {useEffect, useRef, useState, useTransition} from 'react';

const namespace = '4e670095-1016-411a-85c8-1e196e1e6efd';
const appId = '8593ab25-22de-4c94-8ec4-297bff4bfe3e';
const cookieDomain = 'wayward.com';
const isServer = typeof document === 'undefined';
const env = isServer
  ? typeof process !== 'undefined'
    ? process.env.PUBLIC_ENV
    : ''
  : window.ENV.PUBLIC_ENV;
const collectorURL =
  env === 'Production'
    ? 'https://sp.articaanalytics.com'
    : 'https://sp.snowplow.nonprod.artica.app';

type AnalyticsArg = string | Record<any, any>;
type AnalyticsProvider = (
  name: AnalyticsArg,
  b?: AnalyticsArg,
  c?: AnalyticsArg,
  d?: AnalyticsArg,
) => void;
export function useSnowplow(): null | AnalyticsProvider {
  const [analytics, setAnalytics] = useState<null | AnalyticsProvider>(null);
  const isLoading = useRef(false);

  useEffect(() => {
    if (
      !analytics &&
      typeof document !== 'undefined' &&
      !window.articaanalytics &&
      !isLoading.current
    ) {
      isLoading.current = true;
      (function (p, l, o, w, i, n, g) {
        if (!p[i]) {
          p.ArticaAnalytics = p.ArticaAnalytics || [];
          p.ArticaAnalytics.push(i);
          p[i] = function () {
            (p[i].q = p[i].q || []).push(arguments);
          };
          p[i].q = p[i].q || [];
          n = l.createElement(o);
          g = l.getElementsByTagName(o)[0];
          n.async = 1;
          n.src = w;
          g.parentNode.insertBefore(n, g);
        }
      })(
        window,
        document,
        'script',
        'https://assets.articaanalytics.com/scripts/c879ea.js',
        'articaanalytics',
      );

      setAnalytics(() => window.articaanalytics as AnalyticsProvider);

      window.articaanalytics('newTracker', namespace, collectorURL, {
        appId,
        platform: 'web',
        cookieSameSite: 'Lax', // Recommended
        cookieDomain,
        postPath: '/com.articaanalytics/tp2',
        contexts: {
          webPage: true,
          session: true,
          gaCookies: true,
          clientHints: true,
        },
      });
      isLoading.current = false;
    }
  }, [analytics]);

  return analytics;
}
